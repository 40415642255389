:root {
  --default-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    Ubuntu, "Helvetica Neue", Helvetica, Arial, "PingFang SC",
    "Hiragino Sans GB", "Microsoft Yahei UI", "Microsoft Yahei",
    "Source Han Sans CN", sans-serif;
}
*{
    margin: 0px;
    padding: 0px;
    box-sizing: border-box;
}
.login-form{
  margin: auto;
  display: flex;
  width: 100%;
  flex-direction: column;
  padding: 35px 50px;
  align-items: center;
  justify-content: space-around;
  color: rgb(7, 7, 7);  
}

h1 {
  text-align: center;
  padding-bottom: 35px;
}

#input,#input1{
  width: 100%;
  padding: 13px;
  font-size: 16px;
  margin-bottom: 20px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid rgb(7, 7, 7);
  color: rgb(4, 4, 4);
}
#input::placeholder{
  color: rgb(72, 69, 69);
  background-color: transparent;
}
#input1::placeholder{
  background-color: transparent;
  color: rgb(72, 69, 69);
}

.login-button{
  display: flex;
  justify-content: center;
  
}
.login-btn:hover{
  color: black;
  background-color: transparent;
  border: 2px solid #ffe500;
}
.forgot:hover{
  color:#ff1500;
}
.login-btn{
  padding: 10px 30px;
  font-size: 16px;
  color:rgb(9, 9, 9);
  background-color: #ffe500;
  border:2px solid #ffe500;
  margin-top: 19px;
  border-radius: 25px !important;
  cursor: pointer;
}
p{
  line-height: 25px;
  font-size: large;
}
.navName{
  color: #000000;
}









/* ============ Download Button =================== */


/* CSS */
.button-30 {
  align-items: center;
  appearance: none;
  background-color: #FCFCFD;
  border-radius: 4px;
  border-width: 0;
  box-shadow: rgba(45, 35, 66, 0.4) 0 2px 4px,rgba(45, 35, 66, 0.3) 0 7px 13px -3px,#D6D6E7 0 -3px 0 inset;
  box-sizing: border-box;
  color: #36395A;
  cursor: pointer;
  display: inline-flex;
  font-family: "JetBrains Mono",monospace;
  height: 48px;
  justify-content: center;
  line-height: 1;
  list-style: none;
  overflow: hidden;
  padding-left: 16px;
  padding-right: 16px;
  position: relative;
  text-align: left;
  text-decoration: none;
  transition: box-shadow .15s,transform .15s;
  user-select: none;
  -webkit-user-select: none;
  touch-action: manipulation;
  white-space: nowrap;
  will-change: box-shadow,transform;
  font-size: 18px;
}

.button-30:focus {
  box-shadow: #D6D6E7 0 0 0 1.5px inset, rgba(45, 35, 66, 0.4) 0 2px 4px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
}

.button-30:hover {
  box-shadow: rgba(45, 35, 66, 0.4) 0 4px 8px, rgba(45, 35, 66, 0.3) 0 7px 13px -3px, #D6D6E7 0 -3px 0 inset;
  transform: translateY(-2px);
}

.button-30:active {
  box-shadow: #D6D6E7 0 3px 7px inset;
  transform: translateY(2px);
}




/* ====================DATA TABLE CSS===================== */

.custom-datatable .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-datatable .pagination li {
  list-style: none;
  margin: 0 5px; 
}

 .custom-datatable .pagination .active a {
  background-color:transparent;
  color: #000;
  border-color:black;
  padding: 4px;
} 

.custom-datatable .pagination {
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-datatable .pagination a {
  text-decoration: none;
  padding: 4px; 
  border-radius: 4px; 
  text-align: center;
  justify-content: center;
  display: flex;
  color: white;
  border-color: #2f2f41;
  cursor: pointer;
  background-color: #2f2f41;
}


/* Custom styles for the "Showing X of Y entries" text */
.custom-datatable .dataTables_info {
  font-size: 14px;
  font-weight: 500;
  color: rgb(85, 82, 82);
}

/* Custom styles to hide the extra header row */
.custom-datatable thead:last-child {
  display: none;
}

.custom-datatable .dataTables_length label {
  font-size: 14px;
  color: #0a0a0a;
  margin-right: 10px; 
  font-weight: 500;
  margin-left: 10px;
}

/* Custom styles for the select list */
.custom-datatable .dataTables_length select {
  padding: 0px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #f7f3f3;
  color: #030303; 
  text-align: center;
  width: 50px;
  
}


 /* Style the table header cells */
 .custom-datatable thead th {
  background: #2f2f41;
  color: #ffe500;
}

/* ===================== dashboard ========================= */

.cloudImg {
  background-image: linear-gradient(to bottom, rgba(4, 22, 223, 0.5), rgba(93, 213, 235, 0)); /* Linear gradient background */
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(41, 41, 41, 0.1); 
  border: 0px solid;
}
.cloudImg:hover {
  background-image: linear-gradient(to bottom, rgba(93, 213, 235, 0), rgba(4, 22, 223, 0.5)); /* Linear gradient background */
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(41, 41, 41, 0.1); 
  border: 0px solid;
}
.cloudImg1 {
  background-image: linear-gradient(to bottom, rgba(14, 240, 74, 0.5), rgba(9, 218, 51, 0)); /* Linear gradient background */
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(41, 41, 41, 0.1); 
  border: 0px solid;
}
.cloudImg1:hover {
  background-image: linear-gradient(to bottom,  rgba(9, 218, 51, 0), rgba(14, 240, 74, 0.5)); /* Linear gradient background */
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(41, 41, 41, 0.1); 
  border: 0px solid;
}
.cloudImg2 {
  background-image: linear-gradient(to bottom, rgba(249, 63, 6, 0.5), rgba(236, 116, 86, 0.006)); /* Linear gradient background */
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(41, 41, 41, 0.1); 
  border: 0px solid;
}
.cloudImg2:hover {
  background-image: linear-gradient(to bottom, rgba(236, 116, 86, 0.006), rgba(249, 63, 6, 0.5)); /* Linear gradient background */
  background-size: cover; 
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 10px; 
  box-shadow: 0 4px 8px rgba(41, 41, 41, 0.1); 
  border: 0px solid;
}